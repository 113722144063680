<template>
  <div class="product" v-if="item">
    <InternalHeader
      :item="item"
      :isLoading="isLoading"
      :backgroundImage="backgroundImage"
      :backButton="backButton"
      :entity="entity">
    </InternalHeader>
    <router-view :item="item"/>
  </div>
</template>

<script>
import { productsApi } from '@/api';
import { mapGetters } from 'vuex';
import InternalHeader from '@/components/InternalHeader.vue';

export default {
  name: 'Product',
  props: ['id'],
  data() {
    return {
      item: this.$route.params.item || null,
      isLoading: false,
      backButton: {
        name: 'ProductsList',
      },
      entity: 'products',
    };
  },
  components: {
    InternalHeader,
  },
  async created() {
    if (!this.item) {
      this.getRemoteItem();
    }
  },
  computed: {
    ...mapGetters('options', ['currentOptions']),
    backgroundImage() {
      return this.item.acf.header_bg_product || this.currentOptions.header_bg_product;
    },
  },
  methods: {
    async getRemoteItem() {
      try {
        this.isLoading = true;
        const response = await productsApi.getProduct(this.id);
        this.isLoading = false;
        this.item = response.data;
      } catch (error) {
        this.isLoading = false;
        if (error && error.response) {
          this.$notify({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: error.response.data.message,
          });
        }
        throw error;
      }
    },
  },
};
</script>
